import React from 'react'
import InnerBanner from '../component/InnerBanner'
import PublicationList from '../component/PublicationList'

const Publications = () => {
  return (
    <>
     <section>
        <InnerBanner/>
        <PublicationList/>
      </section>
    </>
  )
}

export default Publications