import React, { useState } from 'react'
import InnerTitle from './InnerTitle'
import { useEffect } from "react"
import { useSelector } from 'react-redux';
import Axios from '../Axios'



const Religious = () => {
    var imageBaseURL = Axios.imgBaseUrl+ "religious"
   //  console.log(imageBaseURL)
    const[relData , setRelData ] = useState([]);
    const[isError, setIsError] = useState("");
    const selectedLanguageReducer = useSelector(state => state.selectedLanguageReducer);
   
    const getApiData = async()=>{
      try{
         const res = await Axios.pgBaseUrl.get('religious-program-details')
         setRelData (res.data.religious_activities)
         // console.log(res.data.religious_activities)
      }
      catch(error){
         setIsError(error.message)
      }
      
   }
   useEffect(() => {
     getApiData();
   }, [])
   
  return (
    <>   
         {isError !== "" && <p className='errorTxt'>{isError}</p>}
         <div className="religious-activities-info">
               <div className="container">
                  
                 {selectedLanguageReducer.lang == 'eng' && <InnerTitle heading ="Religious Programmes"/>}
                 {selectedLanguageReducer.lang == 'ben' && <InnerTitle heading ="ধর্মীয় অনুষ্ঠান"/>}
                  <div className='row'>
                     {relData.map((post)=>{
                       const {id,title,description,date,image,title_english,description_english,bengali_date} = post;
                       const formatDate = (dateStr) => {
                        const [year, month, day] = dateStr.split('-');
                        let newDate = `${day}-${month}-${year}`;
                        return newDate;
                      };
                      
                    const formattedDate = formatDate(date);
                     return(
                           <div className="col-md-6 col-lg-4" key = {id}>
                              <div className="religious-activities-item">
                                 <div className="religious-activities-item-thum">
                                    <img src= {`${imageBaseURL}/${image}`} alt="Religious Images"/>
                                 </div>
                                 <div className="religious-activities-item-info">
                                    
                                    {selectedLanguageReducer.lang == 'eng' && <h2>{title_english}</h2>}
                                    {selectedLanguageReducer.lang == 'ben' && <h2>{title}</h2>}
                                    <>
                                    {selectedLanguageReducer.lang == 'eng' && <span>{formattedDate}</span>}
                                    {selectedLanguageReducer.lang == 'ben' && <span>{bengali_date}</span>}
                                    </>
                                    
                                    {/* <>
                                    {selectedLanguageReducer.lang == 'eng' && <p>{description_english}</p>}
                                    {selectedLanguageReducer.lang == 'ben' && <p>{description}</p>}
                                    </> */}
                                    
                                 </div>
                              </div>
                           </div>
                        )
                     })}
                  </div>
                  {/* <div className="row">
                     <div className="col-lg-4">
                        <div className="religious-activities-item">
                           <div className="religious-activities-item-thum">
                              <img src="assets/images/Religious1.png" alt="Religious Images"/>
                           </div>
                           <div className="religious-activities-item-info">
                              <h2>MahaShivratri</h2>
                              <span>Beginning 1st March 2023</span>
                              <p>Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Suspendisse suscipit nunc lacus, a placerat augue tristique.</p>
                           </div>
                        </div>
                     </div>
                     <div className="col-lg-4">
                        <div className="religious-activities-item">
                           <div className="religious-activities-item-thum">
                              <img src="assets/images/Religious1.png" alt="Religious Images"/>
                           </div>
                           <div className="religious-activities-item-info">
                              <h2>MahaShivratri</h2>
                              <span>Beginning 1st March 2023</span>
                              <p>Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Suspendisse suscipit nunc lacus, a placerat augue tristique.</p>
                           </div>
                        </div>
                     </div>
                     <div className="col-lg-4">
                        <div className="religious-activities-item">
                           <div className="religious-activities-item-thum">
                              <img src="assets/images/Religious1.png" alt="Religious Images"/>
                           </div>
                           <div className="religious-activities-item-info">
                              <h2>MahaShivratri</h2>
                              <span>Beginning 1st March 2023</span>
                              <p>Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Suspendisse suscipit nunc lacus, a placerat augue tristique.</p>
                           </div>
                        </div>
                     </div>
                     <div className="col-lg-4">
                        <div className="religious-activities-item">
                           <div className="religious-activities-item-thum">
                              <img src="assets/images/Religious1.png" alt="Religious Images"/>
                           </div>
                           <div className="religious-activities-item-info">
                              <h2>MahaShivratri</h2>
                              <span>Beginning 1st March 2023</span>
                              <p>Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Suspendisse suscipit nunc lacus, a placerat augue tristique.</p>
                           </div>
                        </div>
                     </div>
                     <div className="col-lg-4">
                        <div className="religious-activities-item">
                           <div className="religious-activities-item-thum">
                              <img src="assets/images/Religious1.png" alt="Religious Images"/>
                           </div>
                           <div className="religious-activities-item-info">
                              <h2>MahaShivratri</h2>
                              <span>Beginning 1st March 2023</span>
                              <p>Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Suspendisse suscipit nunc lacus, a placerat augue tristique.</p>
                           </div>
                        </div>
                     </div>
                     <div className="col-lg-4">
                        <div className="religious-activities-item">
                           <div className="religious-activities-item-thum">
                              <img src="assets/images/Religious1.png" alt="Religious Images"/>
                           </div>
                           <div className="religious-activities-item-info">
                              <h2>MahaShivratri</h2>
                              <span>Beginning 1st March 2023</span>
                              <p>Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Suspendisse suscipit nunc lacus, a placerat augue tristique.</p>
                           </div>
                        </div>
                     </div>
                  </div> */}
               </div>
            </div>
    </>
  )
}

export default Religious