import React from 'react'
import InnerBanner from '../component/InnerBanner'
const Blog = () => {
  return (
    <>
        <section>
         <div className="inner-page">
            <InnerBanner/>
            {/* <BlogSection/> */}
         </div>
      </section>
    </>
  )
}

export default Blog