import React from 'react'
import Cards from '../component/Cards'
import InnerBanner from '../component/InnerBanner'

const PhotoGallery = () => {
  return (
   <>
    <section>
        <InnerBanner/>
        <Cards/>
      </section>
   </>
  )
}

export default PhotoGallery