import React, { useState } from 'react'
import { useEffect } from "react"
import { useSelector } from 'react-redux';
import Axios from '../Axios'

 const Welcome = () => {
  const[welcomeData, setWelcomeData] = useState([])
  const[isError, setIsError] = useState("");

  const selectedLanguageReducer = useSelector(state => state.selectedLanguageReducer);
  const getApiData = async() =>{
     try{
      const res = await Axios.pgBaseUrl.get('/about-us')
      setWelcomeData(res.data.data)
     }
     catch(error){
        setIsError(error.message)
     }
  }
  useEffect(() => {
   getApiData() 
 
    
  }, [])

  return (
    <>
  

  {isError !== "" && <p className='errorTxt'>{isError}</p>}
    <div className="welcome-sec">
            <div className="container">
               <div className="wel-inn">
                  {selectedLanguageReducer.lang == 'eng' && <h1>{welcomeData.welcome_heading_english}</h1>}
                  {selectedLanguageReducer.lang == 'ben' && <h1>{welcomeData.welcome_heading}</h1>}
                  <em className="bg-whit"></em>
                  {selectedLanguageReducer.lang == 'eng' && <p dangerouslySetInnerHTML={{__html:welcomeData.welcome_description_english}}></p>}
                  {selectedLanguageReducer.lang == 'ben' && <p dangerouslySetInnerHTML={{__html:welcomeData.welcome_description}}></p>}
                 
                  <div>
                </div>
               </div>
            </div>
    </div> 

    </>
  )
}

export default Welcome