import React from 'react'
import { useSelector } from 'react-redux';
import Axios from '../Axios'

const Parampara = ({parentToChild}) => {
   // console.log(parentToChild)
   const paramparaData = parentToChild
   var imageBaseURL = Axios.imgBaseUrl+ "parampara"
   const selectedLanguageReducer = useSelector(state => state.selectedLanguageReducer);
  return (
    <>
        <div className="parampara-sec">
            <div className="container">
               <div className="custom-heading text-center">
                  
                  <>
                     {selectedLanguageReducer.lang == 'eng' && <h1>Parampara</h1>}
                     {selectedLanguageReducer.lang == 'ben' && <h1>পরম্পরা</h1>}
                  </>
                  <em className="bg-drk"></em>
               </div>
               {paramparaData.map((post)=>{
                  const {id,title,description,image,title_english,description_english} = post;
                  return(
                     <div className="parampara-list" key={id}>
                           <div className="parampara-list-item">
                              <div className="parampara-pic">
                                 <em><img src={`${imageBaseURL}/${image}`} alt="Parampara" className="img-fluid"/></em>
                                 {/* <span>{title}</span> */}
                                 <>
                                    {selectedLanguageReducer.lang == 'eng' && <span>{title_english}</span>}
                                    {selectedLanguageReducer.lang == 'ben' && <span>{title}</span>}
                                    </>
                              </div>
                              <div className="parampara-info">
                                 {/* <p>{description}</p> */}
                                 <>
                                  {selectedLanguageReducer.lang == 'eng' && <span>{description_english}</span>}
                                  {selectedLanguageReducer.lang == 'ben' && <span>{description}</span>}
                                 </>
                              </div>
                           </div>
                     </div>
                  )
               })}
               {/* <div className="parampara-list">
                  <div className="parampara-list-item">
                     <div className="parampara-pic">
                        <em><img src="assets/images/Parampara.jpg" alt="Parampara" className="img-fluid"/></em>
                        <span>Totapuri Baba</span>
                     </div>
                     <div className="parampara-info">
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                        <p>The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.</p>
                     </div>
                  </div>
               </div>
               <div className="parampara-list">
                  <div className="parampara-list-item">
                     <div className="parampara-pic">
                        <em><img src="assets/images/Parampara1.jpg" alt="Parampara" className="img-fluid"/></em>
                        <span>Sri Ramakrishna Paramahamsa Deb</span>
                     </div>
                     <div className="parampara-info">
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                        <p>The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.</p>
                     </div>
                  </div>
               </div>
               <div className="parampara-list">
                  <div className="parampara-list-item">
                     <div className="parampara-pic">
                        <img src="assets/images/Parampara2.jpg" alt="Parampara" className="img-fluid"/>
                        <span>Swami Sivananda</span>
                     </div>
                     <div className="parampara-info">
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                        <p>The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.</p>
                     </div>
                  </div>
               </div>
               <div className="parampara-list">
                  <div className="parampara-list-item">
                     <div className="parampara-pic">
                        <img src="assets/images/Parampara3.png" alt="Parampara" className="img-fluid"/>
                        <span>Swami Jagadishwarananda</span>
                     </div>
                     <div className="parampara-info">
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                        <p>The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.</p>
                     </div>
                  </div>
               </div>
               <div className="parampara-list">
                  <div className="parampara-list-item">
                     <div className="parampara-pic">
                        <img src="assets/images/Parampara4.jpg" alt="Parampara" className="img-fluid"/>
                        <span>Pravrajika Sri Sri Mahagouri Saraswati</span>
                     </div>
                     <div className="parampara-info">
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                        <p>The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.</p>
                     </div>
                  </div>
               </div>
               <div className="parampara-list">
                  <div className="parampara-list-item">
                     <div className="parampara-pic">
                        <img src="assets/images/Parampara5.jpg" alt="Parampara" className="img-fluid"/>
                        <span>Swami Bisuddhananda Saraswati</span>
                     </div>
                     <div className="parampara-info">
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                        <p>The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.</p>
                     </div>
                  </div>
               </div> */}
            </div>
         </div>
    </>
  )
}

export default Parampara