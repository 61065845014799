import { combineReducers } from "redux";

let initialState = {
    lang: 'eng'
}

const selectedLanguageReducer = (state = initialState, action) => {
    if(action.type == "CHANGE_LANGUAGE"){
        return {
            ...state, lang: action.payload
        }
    }

    return state;
}

export default combineReducers({
   selectedLanguageReducer
})