import React from 'react'
import { useNavigate } from 'react-router-dom'
import Axios from '../Axios'
import { useSelector } from 'react-redux';


const ReligiousHome = ({parentToChild}) => {
const religiousText = parentToChild
const topFourReligiousText = religiousText. slice(0, 4)
var imageBaseURL = Axios.imgBaseUrl+ "religious"
const navigate =  useNavigate(); 
const selectedLanguageReducer = useSelector(state => state.selectedLanguageReducer);

// console.log(religiousText)
  return (
    <>
        <div className="religious-sec">
            <div className="container">
               <div className="custom-heading text-center">
                  {/* <h1>Religious Programmes</h1> */}
                  <>
                     {selectedLanguageReducer.lang == 'eng' && <h1>Religious Programmes</h1>}
                     {selectedLanguageReducer.lang == 'ben' && <h1>ধর্মীয় অনুষ্ঠান</h1>}
                  </>
                  <em className="bg-drk"></em>
               </div>
               <div className="religious-programmes">
                 <div className="row">
                  {topFourReligiousText.map((post)=>{
                        const {id, title, description, date,image,title_english,bengali_date}=post
                        const formatDate = (dateStr) => {
                           const [year, month, day] = dateStr.split('-');
                           let newDate = `${day}-${month}-${year}`;
                           return newDate;
                         };
                         const formattedDate = formatDate(date);
                     
                        return(
                           <div className="col-lg-6" key={id}>
                           <>
                          {selectedLanguageReducer.lang == 'eng' && 
                          <div className="programmes-item programmes-item-odd">
                          <div className="programmes-pic-item">
                             <img src={`${imageBaseURL}/${image}`} alt="Religious Programmes"/>
                          </div>
                          <div className="programmes-info-item">
                             <span>{formattedDate}</span>
                             <h3>{title_english}</h3>
                             {/* <p>{description}</p> */}
                          </div>
                       </div>}
                          {selectedLanguageReducer.lang == 'ben' && 
                          <div className="programmes-item programmes-item-odd">
                          <div className="programmes-pic-item">
                             <img src={`${imageBaseURL}/${image}`} alt="Religious Programmes"/>
                          </div>
                          <div className="programmes-info-item">
                             <span>{bengali_date}</span>
                             <h3>{title}</h3>
                             {/* <p>{description}</p> */}
                          </div>
                       </div>}
                          </>
                           
                           
                        </div> 
                        )
                     })

                     }
                   
                     <div className="col-lg-12 mt-3">
                        <div className="btn-view-all">
                           
                           <>
                              {selectedLanguageReducer.lang == 'eng' && <button onClick={()=>{navigate('/religiousProgrammes')}} className="btn-view-all-btn">View All</button>}
                              {selectedLanguageReducer.lang == 'ben' && <button onClick={()=>{navigate('/religiousProgrammes')}} className="btn-view-all-btn">সমস্ত বিবরণ দেখুন</button>}
                           </>
                        </div>
                     </div>
                  </div> 


               </div>
            </div>
         </div>
    </>
  )
}

export default ReligiousHome