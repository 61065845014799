import React from 'react'
import {useEffect} from "react"
import Banner from '../component/Banner'
import Welcome from '../component/Welcome'
import Parampara from '../component/Parampara'
import AshramLife from '../component/AshramLife'
import ReligiousHome from '../component/ReligiousHome'
import HmSocial from '../component/HmSocial'
import Axios from '../Axios'
import { useReducer } from 'react'
import ParamparaIntro from '../component/ParamparaIntro'
  const Home = () => {
  const initialState = {
    datas:[],
    paramparadatas:[],
    socialActivitiesData:[],
    religiousData:[]
  }
  const reducer = (reducerState, action)=>{
    switch(action.type) {
     case "Banner_Data" :
      const updatedData = action.payload.datas
      // console.log(updatedData)
      return{
        ...reducerState,
        datas:updatedData
      }
      case "Parampara_Data" :
      const updatedParamData = action.payload.paramdatas 
      // console.log(updatedParamData) 
      return{
        ...reducerState,
        paramparadatas:updatedParamData
        
        
      }
      case "Social_Activities" :
        const updatedSocialData = action.payload.socialActivitiesData
       return{
        ...reducerState,
        socialActivitiesData:updatedSocialData
       }
       case "Religious_Activities" :
        const updateReligiousData = action.payload.religiousData
       return{
        ...reducerState,
        religiousData:updateReligiousData
       }
      
      default:
        return state;
    }
    
  
   }
  const [state,dispatch] = useReducer(reducer,initialState);
   var getApiData = async() =>{
    
    
      try{
        const res = await Axios.pgBaseUrl.get('/homepage-details')  
        dispatch({
          type:"Banner_Data",
          payload:{
            datas:res.data.banner_images
          
          }
         })
        dispatch({
          type:"Parampara_Data",
          payload:{
            paramdatas:res.data.parampara
          
          }
        })
        dispatch({
          type:"Social_Activities",
          payload:{
            socialActivitiesData:res.data.social_activities
          }
        })
        dispatch(
          {
            type:"Religious_Activities",
            payload:{
              religiousData:res.data.religious_activities
            }
          }
        )
    }
   
   catch(error){
    console.log(error)

   }
 }
   useEffect(() => {
       getApiData()
     
      }, [])

  return (
    <>
   
      <section>
       <Banner parentToChild={state.datas}/>
       <Welcome />
       <ParamparaIntro/>
       <Parampara parentToChild={state.paramparadatas}/>
       <HmSocial parentToChild={state.socialActivitiesData}/>
       <AshramLife/>
       <ReligiousHome parentToChild={state.religiousData}/>
       
      </section> 
    </>
  )
}

export default Home