import React from "react";
import Axios from "../Axios";
import ImageModal from "./ImageModal";
const GalleryImage = ({ data, sshow, onHide, imageNameTop ,image , mshow }) => {
  const a = data;
  // console.log(a);
  var imageBaseURL = Axios.imgBaseUrl + "gallery";

  return (
    <>
      {a.map((item, index) => {
        const { id, name, file } = item;
        return (
          <div className="col-12 col-lg-4" key={id}>
            <div className="video-wrap photo-wrap">
                <div className="imgContainer">
                    <img
                    src={`${imageBaseURL}/${file}`}
                    alt={`${name}`}
                   
                    className = "image"
                  />
                  <div className="overlay">
                    <div className="icon" onClick={() => sshow(`${imageBaseURL}/${file}`, name)}>
                      <i className="fa fa-search"></i>
                    </div>
                </div>
              
              </div>
              {/* <p className="mt-2 mb-0 text-center">{name}</p> */}
              <ImageModal
                        image={image}
                        isShow={mshow}
                        onHide={onHide}
                        // imageNameTop={imageNameTop}
                      />
            </div>
            
          </div>
        );
      })}
    </>
  );
};

export default GalleryImage;
