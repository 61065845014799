import React from "react";
import {useEffect } from "react"
import {Routes,Route} from "react-router-dom";
import Home from "./routes/Home";
import About from "./routes/About";
import Administration from "./routes/Administration";
import SocialActivities from "./routes/SocialActivities";
import ReligiousProgrammes from "./routes/ReligiousProgrammes";
import PhotoGallery from "./routes/PhotoGallery";
import Video from "./routes/Video";
import Blog from "./routes/Blog";
import Publications from "./routes/Publications";
import Donate from "./routes/Donate";
import ContactUs from "./routes/ContactUs";
import Error from "./routes/Error";
import HeaderNavbar from "./component/HeaderNavbar";
import Footer from "./component/Footer";
import ActivitiesDetails from "./component/ActivitiesDetails";
import PublicationDetails from "./component/PublicationDetails";


function App() {
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        autoDisplay: false
      },
      "google_translate_element"
    );
  };
  useEffect(() => {
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);
 

  return (
    <>

      {/* <div id="google_translate_element"></div> */}
      <HeaderNavbar/>
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route exact path='/about' element={<About/>} />
        <Route exact path='/administration' element={<Administration/>} />
        <Route exact path='/socialActivities' element={<SocialActivities/>} />
        <Route exact path='/religiousProgrammes' element={<ReligiousProgrammes />} />
        <Route exact path='/photoGallery' element={<PhotoGallery/>} />
        <Route exact path='/video' element={<Video/>} />
        <Route exact path='/publications' element={<Publications/>} />
        <Route exact path='/donate' element={<Donate/>} />
        <Route exact path='/blog' element={<Blog/>} />
        <Route exact path='/contactUs' element={<ContactUs/>} />
        <Route path='/socialActivities/:activityId' element={<ActivitiesDetails/>} />
        <Route path='/publications/:publicationId' element={<PublicationDetails/>} />
        <Route exact path='*' element={<Error/>} />
      </Routes>
    <Footer/>
    </>
  );
}

export default App;
