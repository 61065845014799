import React, { useState } from 'react'
import InnerTitle from './InnerTitle'
import { useEffect } from "react"
import { useSelector } from 'react-redux';
import Axios from '../Axios'
import { Link } from 'react-router-dom';
const Activities = () => {
   const[activityData, setActivityData] = useState([])
   const[isError, setIsError] = useState("");
   const selectedLanguageReducer = useSelector(state => state.selectedLanguageReducer);
   var imageBaseURL = Axios.imgBaseUrl+ "social"
   const getApiData = async() =>{
      try{
       const res = await Axios.pgBaseUrl.get('/social-activities-details')
        setActivityData(res.data.social_activities)
      }
      catch(error){
         setIsError(error.message)
      }
   }
   useEffect(() => {
    getApiData() 
  
     
   }, [])
   
  return (
    <>
    {isError !== "" && <p className='errorTxt'>{isError}</p>}
   <div className="social-activities-info">
               <div className="container">
                 
                 {selectedLanguageReducer.lang == 'eng' && <InnerTitle heading ="Social Activities"/>}
                 {selectedLanguageReducer.lang == 'ben' && <InnerTitle heading ="সামাজিক কর্ম"/>}
                 
                  <div className='row'>
                     {
                       activityData.map((post)=>{
                        const{id,title,description,image,title_english,description_english} = post
                        return(
                           <div className="col-lg-6" key={id}>
                              <Link to={`${id}`} className="transBorder">
                                    <div className="social-activities-item">
                                       <div className="social-activities-item-thum">
                                          <img src={`${imageBaseURL}/${image}`} alt="SocialImage" />
                                       </div>
                                       <div className="social-activities-item-info">
                                          {/* <h2>{title}</h2> */}
                                          {selectedLanguageReducer.lang == 'eng' && <h2>{title_english}</h2>}
                                          {selectedLanguageReducer.lang == 'ben' && <h2>{title}</h2>}

                                          {selectedLanguageReducer.lang == 'eng' && <p>{description_english?.slice(0,200)}...</p>}
                                          {selectedLanguageReducer.lang == 'ben' && <p>{description.slice(0,200)}...</p>}

                                          
                                          {selectedLanguageReducer.lang == 'eng' && <span className="click-text-small">Read More</span>}
                                          {selectedLanguageReducer.lang == 'ben' && <span className="click-text-small">আরও পড়ুন</span>}
                                             
                                       
                                       </div>
                                    </div>
                              </Link> 

                           </div>
                           
                        )
                       }
                     )}
                  </div>
                  
               </div>
            </div> 
    </>
  )
}

export default Activities