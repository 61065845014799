import React from 'react'
import AboutContent from '../component/AboutContent'
import InnerBanner from '../component/InnerBanner'
const About = () => {
  return (
    <>
    <section>
         <div className="inner-page">
            <InnerBanner/>
           <AboutContent/>
         </div>
      </section>
    </>
  )
}

export default About