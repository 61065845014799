import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useEffect } from "react"
import { useSelector } from 'react-redux';
import Axios from '../Axios'


const AshramLife = () => {
   const navigate =  useNavigate();  
   const[ashramLifeData,setAshramLifeData] = useState();
   const[isError, setIsError] = useState("");
   const selectedLanguageReducer = useSelector(state => state.selectedLanguageReducer);
   var imageBaseURL = Axios.imgBaseUrl+ "gallery"
   const getApiData = async() =>{
      try{
      //  const res = await Axios.pgBaseUrl.get('/photo-gallery')
      //  setAshramLifeData(res.data.gallery_image.slice(0, 6))
       const res = await Axios.pgBaseUrl.get('/homepage-details')
       setAshramLifeData(res.data.gallery_image)
      }
      catch(error){
         setIsError(error.message)
      }
   }
   useEffect(() => {
    getApiData() 
  
     
   }, [])

  return (
   <>
   {isError !== "" && <p className='errorTxt'>{isError}</p>}
    <div className="ashram-pic">
            <div className="ashram-pic-top-right">
               <img src="assets/images/mandala3.png" alt="Texture"/>
            </div>
            <div className="container">
               <div className="custom-heading text-center mb-21">
                 
                  <>
                     {selectedLanguageReducer.lang == 'eng' &&  <h1>Life in Our Ashram</h1>}
                     {selectedLanguageReducer.lang == 'ben' &&  <h1>আমাদের আশ্রমের জীবন</h1>}
                 </>
                  <em className="bg-drk"></em>
               </div>
                <div className='row'>
                {ashramLifeData?.map((post)=>{
                  const{id,name,file} = post
                  return(
                  <div className="col-12 col-lg-4" key={id}>
                     <div className="ashramLifeImg">
                       <img src={`${imageBaseURL}/${file}`} alt=""/>
                       {/* <p className="mb-0 text-center">{name}</p> */}
                     </div>
                 </div>
                  )
                })} 
               </div>
                 <div className="row">
                  <div className="col-lg-12">
                     {/* <div className="btn-view-all">
                     <button onClick={()=>{navigate('/photoGallery')}} className="btn-view-all-btn">View All</button>
                     </div> */}
                                 <>
                                    {selectedLanguageReducer.lang == 'eng' && 
                                    <>
                                    <div className="btn-view-all">
                                          <button onClick={()=>{navigate('/photoGallery')}} className="btn-view-all-btn">View All</button>
                                    </div>
                                    </>
                                    }
                                    {selectedLanguageReducer.lang == 'ben' && 
                                    <>
                                    <div className="btn-view-all">
                                          <button onClick={()=>{navigate('/photoGallery')}} className="btn-view-all-btn">আরো দেখুন</button>
                                    </div>
                                    </>
                                    
                                    }
                                    </>
                  </div>
               </div>
            </div>
            <div className="ashram-pic-bottom-left">
               <img src="assets/images/mandala2.png" alt="Texture"/>
            </div>
         </div>
   </>
  )
}

export default AshramLife