import React from 'react'
import PropTypes from "prop-types";

const YoutubeEmbed = ({embedId}) => {
  return (
    <div className="video-responsive">

<div className="embed-responsive embed-responsive-16by9">
                       <iframe
                          src={`https://www.youtube.com/embed/${embedId}`}
                          width="100%"
                          height="315"
                          style={{ border: 0 }}
                          allowFullScreen
                          loading="lazy"
                          referrerPolicy="no-referrer-when-downgrade"
                          title="Ashram-Programme"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                        ></iframe>
                      </div>
  </div>
  )
}
YoutubeEmbed.propTypes = {
    embedId: PropTypes.string.isRequired
  };
export default YoutubeEmbed