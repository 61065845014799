import React, { useState } from 'react'
import InnerTitle from './InnerTitle'
import { useEffect } from "react"
import { useSelector } from 'react-redux';
import Axios from '../Axios'

const AdminTable = () => {
   const[adminTableData, setadminTableData] = useState([])
   const[isError, setIsError] = useState("");
   const selectedLanguageReducer = useSelector(state => state.selectedLanguageReducer);
   const getApiData = async() =>{
      try{
       const res = await Axios.pgBaseUrl.get('/administration')
       setadminTableData(res.data.data)
      }
      catch(error){
         setIsError(error.message)
      }
   }
   useEffect(() => {
    getApiData() 
  
     
   }, [])
  return (
    <>
        {isError !== "" && <p className='errorTxt'>{isError}</p>}
         <div className="administration-info">
               <div className="container">
                 <InnerTitle heading ="Administration"/>
                  <div className="administration-tbl pb-5">
                     <b>Board of Trustees</b>
                     <div className="table-responsive mt-3 admins-table">
                        <table className="table table-bordered table-striped">
                           <thead>
                              <tr>
                                 {/* <th>Sl. No.</th> */}
                                 <th>Name</th>
                                 <th>Responsibility</th>
                              </tr>
                           </thead>
                           <tbody>
                              
                             {adminTableData.map((item)=>{
                               const {id,name,responsibility,name_english,responsibility_english} =item
                               return(
                                 <tr key={id}>
                                 {/* <td>{id}</td> */}
                                 <td>
                                    <>
                                    {selectedLanguageReducer.lang == 'eng' && <span>{name_english}</span>}
                                    {selectedLanguageReducer.lang == 'ben' && <span>{name}</span>}
                                    </>
                                 </td>
                                 <td>
                                 <>
                                    {selectedLanguageReducer.lang == 'eng' && <span>{responsibility_english}</span>}
                                    {selectedLanguageReducer.lang == 'ben' && <span>{responsibility}</span>}
                                    </>
                                 </td>
                              </tr>
                               )
                             })}
                           </tbody>
                        </table>
                     </div>
                  </div>
               </div>
            </div>
    </>
  )
}

export default AdminTable