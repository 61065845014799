import React, { useState} from 'react'
import Axios from '../Axios'
import toast, { Toaster } from 'react-hot-toast';
import { useSelector } from 'react-redux';
 const Form = () => { 
    const successNotify = () => toast.success('Query submitted successfully', {
        style: {
            border: '1px solid #9b3131',
            padding: '16px',
            color: '#9b3131',
          },
          iconTheme: {
            primary: '#9b3131',
            secondary: '#FFFAEE',
          },
    });

    const errorNotify = () => toast.error('Something went wrong!', {
        style: {
            border: '1px solid #9b3131',
            padding: '16px',
            color: '#9b3131',
          },
          iconTheme: {
            primary: '#9b3131',
            secondary: '#FFFAEE',
          },
    });

    const[formSubmitError, setFormSubmitError] = useState(false)
    const selectedLanguageReducer = useSelector(state => state.selectedLanguageReducer);
    const [user, setUser]  = useState({name:'', email:'', mobile:'', subject:'', message:''}) ;
    const {name, email, mobile, subject, message} = user
    const [formError, setFormError] = useState({})
    const handleChange = (e) => {
            setUser ({...user, [e.target.name]: e.target.value})
    }
    const [loading, setLoading] = useState(false);
 const[formErrors,setFormErrors] = useState({})
 const handleSubmit = (e) => {
     e.preventDefault();
    console.log('Form is submitted')
    setFormError(validate(user));
    // console.log(user);

    const postApiData = async() =>{
        setLoading(true);
        try{
         const res = await Axios.pgBaseUrl.post('/contact-form',{
        name:  name,
        email: email,
        mobile:  mobile,
        subject: subject,
        message: message
        })
        setLoading(false);
        setUser({name:'', email:'', mobile:'', subject:'', message:''});
        successNotify()
        console.log(res)
       }
        catch(error){
            errorNotify();
            setLoading(false);
            console.log(error)
        }
     }
    
      postApiData() 
  
 }
 const validate = (values) =>{
    const errors = {}
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    
 }
  return (
    <>
        <div className="col-md-8 col-sm-12">
                        <div className="contact-from">
                           
                           <>
                             {selectedLanguageReducer.lang == 'eng' && <h1>Send your queries to us</h1>}
                             {selectedLanguageReducer.lang == 'ben' && <h1>আমাদের আপনার প্রশ্ন পাঠান</h1>}
                           </>
                           <div className="row">
                           
                            <form onSubmit={handleSubmit}>
                                
                                
                            <Toaster
                                position="top-right"
                                reverseOrder={false}
                            />
                                {selectedLanguageReducer.lang == 'eng' && 
                                     <div className='row'>
                                     <div className="col-sm-6">
                                         <div className="elementor-field-required">
                                             <label className="field-label">Name<span>*</span></label>
                                             <input type="text" className="form-field form-control" 
                                              placeholder="Enter Here" 
                                              name='name' 
                                              onChange={handleChange} value={name} required/>
                                         </div>
                                     </div>
                                     <div className="col-sm-6">
                                         <div className="elementor-field-required">
                                             <label className="field-label">Email<span>*</span></label>
                                             <input type="email" className="form-field form-control" 
                                              placeholder="Enter Here" 
                                              name='email' onChange={handleChange} value={email} required/>
                                         </div>
                                     </div>
                                     <div className="col-sm-6">
                                         <div className="elementor-field-required">
                                             <label className="field-label">Phone Number<span>*</span></label>
                                             <input type="tel" 
                                             className="form-field form-control" 
                                             placeholder="Enter Here" 
                                             name='mobile' 
                                             onChange={handleChange} value={mobile} required/>
                                         </div>
                                     </div>
                                     <div className="col-sm-6">
                                         <div className="elementor-field-required">
                                             <label className="field-label">Subject<span>*</span></label>
                                             <input type="text"
                                              className="form-field form-control" 
                                              placeholder="Enter Here" name='subject' 
                                              onChange={handleChange} value={subject} required/>
                                         </div>
                                     </div>
                                     <div className="col-sm-12">
                                         <div className="elementor-field-required">
                                             <label className="field-label">Message<span>*</span></label>
                                             <textarea 
                                             placeholder="Enter Here" 
                                             name='message' 
                                             onChange={handleChange} 
                                             value={message} required></textarea>
                                         </div>
                                     </div>
                                     <div className="col-sm-12">
                                         <button type="submit" className="pg_btn ne_pg_btns" disabled={loading}>Submit Now</button>
                                        {/* {formSubmitError?<p>All fields must be filled.</p>:<p>Form is submitted successfully</p>}  */}
                                     </div>
                                 </div>
                                
                                
                                }
                                {selectedLanguageReducer.lang == 'ben' && 
                                
                                <div className='row'>
                                <div className="col-sm-6">
                                    <div className="elementor-field-required">
                                        <label className="field-label">নাম<span>*</span></label>
                                        <input type="text" className="form-field form-control" 
                                         placeholder="এখানে লিখুন" 
                                         name='name' 
                                         onChange={handleChange} value={name} required/>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="elementor-field-required">
                                        <label className="field-label">ইমেইল<span>*</span></label>
                                        <input type="email" className="form-field form-control" 
                                         placeholder="এখানে লিখুন" 
                                         name='email' onChange={handleChange} value={email} required/>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="elementor-field-required">
                                        <label className="field-label">ফোন নম্বর<span>*</span></label>
                                        <input type="tel" 
                                        className="form-field form-control" 
                                        placeholder="এখানে লিখুন" 
                                        name='mobile' 
                                        onChange={handleChange} value={mobile} required/>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="elementor-field-required">
                                        <label className="field-label">বিষয়<span>*</span></label>
                                        <input type="text"
                                         className="form-field form-control" 
                                         placeholder="এখানে লিখুন" name='subject' 
                                         onChange={handleChange} value={subject} required/>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="elementor-field-required">
                                        <label className="field-label">বার্তা<span>*</span></label>
                                        <textarea 
                                        placeholder="এখানে লিখুন" 
                                        name='message' 
                                        onChange={handleChange} 
                                        value={message} required></textarea>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <button type="submit" className="pg_btn ne_pg_btns" disabled={loading}>এখনই জমা দিন</button>
                                </div>
                            </div>
                                
                                }



                               </form> 
                           </div>
                        </div>
                     </div>
    </>
  )
}

export default Form