import React from "react";
import InnerTitle from "./InnerTitle";
import Axios from "../Axios";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import GalleryImage from "./GalleryImage";
import Pagination from "./Pagination";
import YoutubeEmbed from "./YoutubeEmbed";

const Cards = () => {
  const [imageName, setImageName] = useState([]);
  const [videoName, setVideoName] = useState([]);
  const [image, setImage] = useState("");
  const [imageNameTop, setImageNameTop] = useState("");
  const [show, setShow] = useState(false);
  const selectedLanguageReducer = useSelector(state => state.selectedLanguageReducer);
  const handleShow = (image, imageNameTop) => {
    setShow(true);
    setImage(image);
    setImageNameTop(imageNameTop);
  };
  const handleClose = () => setShow(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(15);;
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentPosts = imageName.slice(firstPostIndex, lastPostIndex);

  const getApiData = () => {
    const galleryImageApi = Axios.pgBaseUrl.get("/photo-gallery");
    const galleryVideoApi = Axios.pgBaseUrl.get("/video-gallery");
    axios.all([galleryImageApi, galleryVideoApi]).then(
      axios.spread((...allData) => {
        const allImage = allData[0].data.gallery_image;
        const allVideo = allData[1].data.video;
        setImageName(allImage);
        setVideoName(allVideo);
      })
    );
  };

  useEffect(() => {
    getApiData();
  }, []);

  return (
    <>
      <div className="publications">
        <div className="container">
          {window.location.pathname === "/photoGallery" ? (
            // <InnerTitle heading="Photo Gallery" />
            <>
              {selectedLanguageReducer.lang == 'eng' && <InnerTitle heading="Photo Gallery" />}
              {selectedLanguageReducer.lang == 'ben' && <InnerTitle heading="ফটো গ্যালারি" />}
            </>


          ) : (
            <>
              {selectedLanguageReducer.lang == 'eng' && <InnerTitle heading="Video Gallery" />}
              {selectedLanguageReducer.lang == 'ben' && <InnerTitle heading="ভিডিও  গ্যালারি" />}
            </>
          )}
          <div className="row mt-5">
            {window.location.pathname === "/photoGallery" ? (
              <>
              <GalleryImage
                data={currentPosts}
                sshow={handleShow}
                onHide={handleClose}
                imageNameTop={imageNameTop}
                image={image}
                mshow={show}
              />
              <Pagination 
                totalPosts={imageName.length}
                postsPerPage={postsPerPage}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}/>
              </>
              
              
              ) : (
              videoName.map((vpost) => {
                const {id, name, file } = vpost;
                const getYouTubeVideoId = (url) =>{
                  // var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
                  // var match = url.match(regExp);
                  // return (match && match[7].length === 11) ? match[7] : false;
                  let regex = /(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/gm;
                  return regex.exec(url)[3];
                }
                var videoLink = getYouTubeVideoId(file)
                return (
                  <div className="col-12 col-lg-4" key={id}>
                    <div className="video-wrap">
                      <YoutubeEmbed embedId={videoLink}/>
                      <p className="mb-0 text-center">{name}</p>
                    </div>
                  </div>
                );
              })
              
            )
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default Cards;
