import React, { Component } from 'react'

export default class InnerTitle extends Component {
  render(props) {
    return (
      <>
        <div className="custom-heading text-center">
                     <h1>{this.props.heading}</h1>
                     <em className="bg-drk"></em>
          </div>
      </>
    )
  }
}
