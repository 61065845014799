import React, { useState } from 'react'
import AboutCard from './AboutCard'
import { useEffect } from "react"
import { useSelector } from 'react-redux';
import Axios from '../Axios'

const AboutContent = () => {
  const[aboutData,setAboutData] = useState();
  const[isError, setIsError] = useState("");
  const selectedLanguageReducer = useSelector(state => state.selectedLanguageReducer);
  const getApiData = async() =>{
    try{
     const res = await Axios.pgBaseUrl.get('/about-us')
     setAboutData(res.data.data)
    //  console.log(res.data.data)
    }
    catch(error){
       setIsError(error.message)
    }
 }
 useEffect(() => {
  getApiData() 

   
 }, [])
  return (
    <> 
        {isError !== "" && <p className='errorTxt'>{isError}</p>}
        <AboutCard aboutData ={aboutData} about/>
    </>
  )
}

export default AboutContent