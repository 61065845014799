import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";

const ImageModal = ({ image, isShow, onHide , props,imageNameTop}) => {
  return (
    <>
            <Modal show={isShow} onHide={onHide}  
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered className="galleryModal">
                <Modal.Header closeButton>
                {/* <p className="mb-0">{imageNameTop}</p> */}
                </Modal.Header>
                <Modal.Body>
                <img src={image} alt="image" className="img-fluid2" />
                </Modal.Body>
            
            </Modal>
   
     
    </>
  );
};

export default ImageModal;
