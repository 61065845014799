import React from 'react'
import Cards from '../component/Cards'
import InnerBanner from '../component/InnerBanner'


const Video = () => {
  return (
    <>
   
    <section>
        <InnerBanner heading="Videos"/>
        <Cards/>
    </section>
    </>
  )
}

export default Video