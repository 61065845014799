import React from 'react'

const InnerBanner = () => {
  return (
    <>
    <div className="inner-banner">
               <img src="/assets/images/inner-banner.png" alt="Banner"/>
    </div>
    </>
  )
}

export default InnerBanner