import React, {useState} from "react";
import { NavLink, Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useSelector, useDispatch } from "react-redux";
import languageChangeAction from "../actions";


const HeaderNavbar = () => {
  const dispatch = useDispatch();
  const [tabActive, setTabActive] = useState(false)
  const [tabActive2, setTabActive2] = useState(false)
  const selectedLanguageReducer = useSelector(state => state.selectedLanguageReducer);
  // console.log(selectedLanguageReducer);

  const changeLanguage = (event) => {
    dispatch(languageChangeAction(event.target.value));
    console.log("Value",event.target.value)
  }

  // useEffect(() => {
  //   console.log(window.location.pathname)
  //   if(window.location.pathname == '/about' || window.location.pathname == '/administration'){
  //     setTabActive(true)
  //   } else {
  //     setTabActive(false)
  //   }
  //   console.log("Tab active", tabActive)
  // })
  
  const menuActive =()=>{
      setTabActive(true)
      setTabActive2(false)
  }
  const menuActive2 =()=>{
    setTabActive(false)
    setTabActive2(true)

}
  const menuInactive =()=>{
      setTabActive(false)
      setTabActive2(false)
  }
  return (
    <>
      <header>
        <div className="header-logo">
          <div className="container">
            <div className="logo">
              <Link to="/">
                <img src="/assets/images/logo.png" alt="Logo" />
              </Link>
              {/* <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#main_nav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon">
                  <i className="fa fa-bars"></i>
                </span>
              </button> */}
            </div>

            {/* <div className="header-info">
              <div className="header-contact-info">
                <ul>
                  <li>
                    <a href="mailto:info@domain.com">
                      <i className="fa fa-envelope" aria-hidden="true"></i>
                      ramkrishnadharmachakra@gmail.com
                    </a>
                  </li>
                  <li>
                    <a href="tel:+913326548075">
                      <i className="fa fa-phone" aria-hidden="true"></i>
                      +913326548075
                    </a>
                  </li>
                </ul>
              </div>
              <div className="header-lan-info">
                <img src="/assets/images/language.png" alt="Language" />
                <select onInput={changeLanguage}>
                  <option value="eng">English</option>
                  <option value="ben">Bengali</option>
                </select>
              </div>
            </div> */}
            <>
           
            <div className="header-info">
            {selectedLanguageReducer.lang == 'eng' &&  
            <div className="header-contact-info">
              <ul>
                <li>
                  <a href="mailto:info@domain.com">
                    <i className="fa fa-envelope" aria-hidden="true"></i>
                    ramkrishnadharmachakra@gmail.com
                  </a>
                </li>
                <li>
                  <a href="tel:+913326548075">
                    <i className="fa fa-phone" aria-hidden="true"></i>
                    +913326548075
                  </a>
                </li>
              </ul>
            </div>
              }
             {selectedLanguageReducer.lang == 'ben' && 
              <div className="header-contact-info">
               <ul>
                 <li>
                   <a href="mailto:info@domain.com">
                     <i className="fa fa-envelope" aria-hidden="true"></i>
                     ramkrishnadharmachakra@gmail.com
                   </a>
                 </li>
                 <li>
                   <a href="tel:+913326548075">
                     <i className="fa fa-phone" aria-hidden="true"></i>
                     +৯১৩৩২৬৫৪৮0৭৫
                   </a>
                 </li>
               </ul>
               </div>
              }




            <div className="header-lan-info">
              <img src="/assets/images/language.png" alt="Language" />
              <select onInput={changeLanguage}>
                <option value="eng">English</option>
                <option value="ben">Bengali</option>
              </select>
            </div>
          </div>
       
             
           
            </>

            

          </div>
        </div>
        <div className="header-menu">
          {/* <div className="container">
               <nav className="navbar navbar-expand-lg">
                  <div className="collapse navbar-collapse" id="main_nav">
                     <ul className="navbar-nav mx-auto">
                        <li className="nav-item"> <NavLink end to="/" className='nav-link' > Home </NavLink> </li>
                        <li className="nav-item dropdown">
                           <a className="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown">  Organization  </a>
                           <ul className="dropdown-menu fade-up">
                              <li><NavLink to='/about' className='dropdown-item'>About Us</NavLink></li>
                              <li><NavLink to='/administration' className='dropdown-item'> Administration </NavLink></li>
                           </ul>
                        </li>
                        <li className="nav-item"><NavLink to='/socialActivities' className='nav-link'> Social Activities </NavLink></li>
                        <li className="nav-item"><NavLink to='/religiousProgrammes' className='nav-link'> Religious Programmes </NavLink></li>
                         <li className="nav-item dropdown">
                           <a className="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown">  Media </a>
                           <ul className="dropdown-menu fade-up">
                              <li><NavLink to='/photoGallery' className='dropdown-item'>Photo Gallery</NavLink></li>
                              <li><NavLink to='/video' className='dropdown-item'>Video</NavLink></li>
                           </ul>
                        </li>
                        <li className="nav-item"><NavLink to='/publications' className='nav-link'> Publications </NavLink></li>
                        <li className="nav-item"><NavLink to='/donate' className='nav-link'> Donate </NavLink></li>
                        <li className="nav-item"><NavLink to='/contactUs' className='nav-link'> Contact Us </NavLink></li>
                     </ul>
                  </div>
               </nav>
            </div> */}
          <Navbar collapseOnSelect expand="lg">
            <Container>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">

              <>
                {selectedLanguageReducer.lang == 'eng' &&
                   <Nav className="mx-auto">
                   <NavLink end to="/" className='nav-link' onClick={menuInactive}> Home </NavLink>
                   <NavDropdown title="Organization" id="collasible-nav-dropdown" className= {tabActive ? "bgActive" : ""}>
                    <NavDropdown.Item>
                        <Link to="/about" className="ddMenu" onClick={menuActive}>About Us</Link>
                     </NavDropdown.Item>
                     <NavDropdown.Item>
                        <Link to="/administration" className="ddMenu"  onClick={menuActive}>Administration</Link>
                     </NavDropdown.Item>
                   </NavDropdown>
                   <NavLink to='/socialActivities' className='nav-link' onClick={menuInactive}> Social Activities </NavLink>
                   <NavLink to='/religiousProgrammes' className='nav-link' onClick={menuInactive}> Religious Programmes </NavLink>
                   <NavDropdown title="Media" id="collasible-nav-dropdown" className= {tabActive2 ? "bgActive" : ""}>
                   <NavDropdown.Item>
                        <Link to="/photoGallery" className="ddMenu" onClick={menuActive2}>Photo Gallery</Link>
                     </NavDropdown.Item>
                     <NavDropdown.Item>
                        <Link to="/video" className="ddMenu"  onClick={menuActive2}>Video</Link>
                     </NavDropdown.Item>
                   </NavDropdown>
                   <NavLink to='/publications' className='nav-link' onClick={menuInactive}> Publications </NavLink>
                   <NavLink to='/donate' className='nav-link' onClick={menuInactive}> Donate </NavLink>
                   <NavLink to='/blog' className='nav-link' onClick={menuInactive}> Blog </NavLink>
                   <NavLink to='/contactUs' className='nav-link' onClick={menuInactive}> Contact Us </NavLink>
                 </Nav>
                
                }
                {selectedLanguageReducer.lang == 'ben' &&
                   <Nav className="mx-auto">
                   <NavLink end to="/" className='nav-link' > হোম</NavLink>
                   <NavDropdown title="সংগঠন" id="collasible-nav-dropdown" className= {tabActive ? "bgActive" : ""}>
                    <NavDropdown.Item>
                        <Link to="/about" className="ddMenu" onClick={menuActive}>সম্পর্কিত</Link>
                     </NavDropdown.Item>
                     <NavDropdown.Item>
                        <Link to="/administration" className="ddMenu"  onClick={menuActive}>প্রশাসন</Link>
                     </NavDropdown.Item>
                   </NavDropdown>

                   {/* <NavDropdown title="সংগঠন" id="collasible-nav-dropdown">
                     <NavDropdown.Item href='/about'>সম্পর্কিত</NavDropdown.Item>
                     <NavDropdown.Item href='/administration'>প্রশাসন</NavDropdown.Item>
                   </NavDropdown> */}



                   <NavLink to='/socialActivities' className='nav-link' onClick={menuInactive}> সামাজিক কর্ম </NavLink>
                   <NavLink to='/religiousProgrammes' className='nav-link' onClick={menuInactive}> ধর্মীয় অনুষ্ঠান </NavLink>
                   {/* <NavDropdown title="মিডিয়া" id="collasible-nav-dropdown">
                     <NavDropdown.Item href='/photoGallery'>ফটো গ্যালারি</NavDropdown.Item>
                     <NavDropdown.Item href='/video'>ভিডিও</NavDropdown.Item>
                   </NavDropdown> */}

                   <NavDropdown title="মিডিয়া" id="collasible-nav-dropdown" className= {tabActive2 ? "bgActive" : ""}>
                   <NavDropdown.Item>
                        <Link to="/photoGallery" className="ddMenu" onClick={menuActive2}>ফটো গ্যালারি</Link>
                     </NavDropdown.Item>
                     <NavDropdown.Item>
                        <Link to="/video" className="ddMenu"  onClick={menuActive2}>ভিডিও</Link>
                     </NavDropdown.Item>
                   </NavDropdown>
                   <NavLink to='/publications' className='nav-link' onClick={menuInactive}> প্রকাশনা </NavLink>
                   <NavLink to='/donate' className='nav-link' onClick={menuInactive}> দান করুন </NavLink>
                   <NavLink to='/contactUs' className='nav-link' onClick={menuInactive}> যোগাযোগ করুন </NavLink>
                 </Nav>
                }
              </>
               
                
               </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
      </header>
    </>
  );
};

export default HeaderNavbar;
