import React from 'react'
import InnerBanner from '../component/InnerBanner'
import InnerTitle from '../component/InnerTitle'
import Form from '../component/Form'
import { useSelector } from 'react-redux';
const ContactUs = () => {
   const selectedLanguageReducer = useSelector(state => state.selectedLanguageReducer); 
  return (
    <>
      <section>
         <div className="inner-page">
            <InnerBanner/>
            <div className="contact-us-info">
               <div className="container">
                  
                  <>
                     {selectedLanguageReducer.lang == 'eng' && <InnerTitle heading ="Contact Us"/>}
                     {selectedLanguageReducer.lang == 'ben' && <InnerTitle heading ="যোগাযোগ করুন"/>}
                  </>
                  <div className="row">
                    <Form/>
                    {selectedLanguageReducer.lang == 'eng' && 
                     <div className="col-md-4 col-sm-12">
                     <div className="contact-from">
                        <h1>Quick Contact</h1>
                        <div className="cont-box">
                           <i className="fa fa-envelope" aria-hidden="true"></i>
                           <div>
                              <h3>Mail Us</h3>
                              <p><a href="mailto:ramkrishnadharmachakra@gmail.com">ramkrishnadharmachakra@gmail.com</a> </p>
                           </div>
                        </div>
                        <div className="cont-box">
                           <i className="fa fa-phone" aria-hidden="true"></i>
                           <div>
                              <h3>Call Us</h3>
                              <p><a href="tel:913326548075">913326548075</a></p>
                              <p><a href="tel:919830043596">919830043596</a></p>
                              <p><a href="tel:919804473647">919804473647</a></p>
                           </div>
                        </div>
                        <div className="cont-box">
                           <i className="fa fa-map-marker" aria-hidden="true"></i>
                           <div>
                              <h3>Visit Us</h3>
                              <p><b>Sri Ramkrishna Dharma Chakra</b><br/>
                              211 A, Girish Ghosh Road, Belur,<br/> P.O.- Belurmath,<br/> Howrah -7111202</p>
                           </div>
                        </div>
                        <div className="social-icons-cont text-center">
                           <h2>Connect With Facebook</h2>
                           <div class="face-link"><a href="https://www.facebook.com/"><i class="fa fa-facebook"></i></a></div>
                        </div>
                     </div>
                  </div>
                    }
                    {selectedLanguageReducer.lang == 'ben' && 
                     <div className="col-md-4 col-sm-12">
                     <div className="contact-from">
                        <h1>দ্রুত যোগাযোগ</h1>
                        <div className="cont-box">
                           <i className="fa fa-envelope" aria-hidden="true"></i>
                           <div>
                              <h3>আমাদের মেইল ​​করুন</h3>
                              <p><a href="mailto:ramkrishnadharmachakra@gmail.com">ramkrishnadharmachakra@gmail.com</a> </p>
                           </div>
                        </div>
                        <div className="cont-box">
                           <i className="fa fa-phone" aria-hidden="true"></i>
                           <div>
                              <h3>আমাদের কল করুন</h3>
                              <p><a href="tel:913326548075">৯১৩৩২৬৫৪৮0৭৫</a></p>
                              <p><a href="tel:919830043596">৯১৯৮৩00৪৩৫৯৬</a></p>
                              <p><a href="tel:919804473647">৯১৯৮0৪৪৭৩৬৪৭</a></p>
                           </div>
                        </div>
                        <div className="cont-box">
                           <i className="fa fa-map-marker" aria-hidden="true"></i>
                           <div>
                              <h3>আমাদের ঠিকানা</h3>
                              <p><b>শ্রী রামকৃষ্ণ ধর্ম চক্র</b><br/>
                              211 এ, গিরিশ ঘোষ রোড, বেলুড়<br/> পিও- বেলুড়মঠ,<br/>হাওড়া -৭১১১২0২</p>
                           </div>
                        </div>
                        <div className="social-icons-cont text-center">
                           <h2>সামাজিক সংযোগ</h2>
                           <ul>
                              <li> <a href="https://www.facebook.com/groups/722159485651708/?mibextid=6NoCDW" target="_blank" rel="noopener noreferrer"><img src="assets/images/fa1.png" alt="Socail"/></a></li>
                           </ul>
                        </div>
                     </div>
                  </div>
                    }


                     


                     <div className="col-12">
                        <div className="contact-from contact-map">
                           <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d3682.8390949971754!2d88.35134281443493!3d22.622483036840062!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sSri%20Ramakrishna%20Dharmachakra%20211%20A%2C%20Girish%20Ghosh%20Road%2C%20Belur%2C%20P.O.-%20Belurmath%2C%20Howrah%20-7111202!5e0!3m2!1sen!2sin!4v1666072707110!5m2!1sen!2sin"
                            width="100%" height="450"
                             style={{border:0}} 
                             allowFullScreen="" 
                             loading="lazy" referrerPolicy="no-referrer-when-downgrade" title="contactMap"></iframe>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
    </>
  )
}

export default ContactUs