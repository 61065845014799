import React, { useState } from 'react'
import Axios from '../Axios'
import { useEffect } from "react"
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const PublicationList = () => {
   const[publicationData, setPublicationData] = useState([])
   const[isError, setIsError] = useState("");
   const selectedLanguageReducer = useSelector(state => state.selectedLanguageReducer);
   var imageBaseURL = Axios.imgBaseUrl+ "publication"
   const getApiData = async() =>{
      try{
       const res = await Axios.pgBaseUrl.get('/publication')
       setPublicationData(res.data.publications)
      }
      catch(error){
         setIsError(error.message)
      }
   }
   useEffect(() => {
    getApiData() 
  
     
   }, [])
  return (
    <>
        {isError !== "" && <p className='errorTxt'>{isError}</p>}
        <div className="publications publicationsBook">
            <div className="container">
               <div className="custom-heading text-center mb-21">
                  
                  {selectedLanguageReducer.lang == 'eng' && <h1>Publications</h1>}
                  {selectedLanguageReducer.lang == 'ben' && <h1>প্রকাশনা</h1>}
                  <em className="bg-drk"></em>
               </div>
              <div className="row">
                {
                   publicationData.map((publicationPost)=>{
                    const{id,title,description,image,available,title_english,description_english} = publicationPost
                    return(
                        <div className="col-12 col-lg-4" key= {id}>
                            <div className="publication-book  hvr-float-shadow">
                            <div className="social-activities-item-thum">
                            {selectedLanguageReducer.lang == 'eng' && 
                              <>
                              {available === "Y" ? <span className='avail'>Available</span> 
                              : <span className='notAvail'>Not Available</span>}
                              </> 
                              }
                              {selectedLanguageReducer.lang == 'ben' && 
                              <>
                              {available === "Y" ? <span className='avail'>উপলব্ধ</span> 
                              : <span className='notAvail'>অনুপলব্ধ</span>}
                              </>
                              }
                                    {image === null ? <img src='/assets/images/placeholder-image.webp' alt="PublicationImage" /> : <img src={`${imageBaseURL}/${image}`} alt="PublicationImage" />}
                                   

                                 </div>
                            <div className="social-activities-item-info">
                                
                                {selectedLanguageReducer.lang == 'eng' && <h3>{title_english}</h3>}
                                {selectedLanguageReducer.lang == 'ben' && <h3>{title}</h3>}
                                {selectedLanguageReducer.lang == 'eng' &&  <p>{description_english?.slice(0,200)}...</p>}
                                {selectedLanguageReducer.lang == 'ben' &&  <p>{description.slice(0,200)}...</p>}
                               
                                       <Link to={`${id}`}>
                                          {selectedLanguageReducer.lang == 'eng' && <span className="click-text-small">Read More</span>}
                                          {selectedLanguageReducer.lang == 'ben' && <span className="click-text-small">আরও পড়ুন</span>}
                                       </Link> 
                            </div>


                            
                            </div>
                        </div>
                    )
                   }) 
                }
                 
              </div>
            </div>
         </div>
    </>
  )
}

export default PublicationList