import axios from "axios";

   
   const Axios = axios.create(
    {
     baseURL:"https://ramkrishnadharmachakra.in/ramkrishna_dharma_chakra/api/"
    }
  
   )

   const imageBaseURL = 'https://ramkrishnadharmachakra.in/ramkrishna_dharma_chakra/storage/app/public/'
   
    let mainUrl={
      pgBaseUrl:Axios,
      imgBaseUrl:imageBaseURL
    }


 export default mainUrl;