import React from 'react'
import Axios from '../Axios'
import Carousel from 'react-bootstrap/Carousel';
const Banner = ({parentToChild}) => {
   const bannerData = parentToChild
  //  console.log(bannerData)
  var imageBaseURL = Axios.imgBaseUrl+"banner"
  return (
    <>
    <div className="banner">
     <Carousel>
     {bannerData.map((bannerPost)=>{
                      const {id,banner_image} = bannerPost;
                      return(
                        <Carousel.Item interval={4000}  key = {id}>
                        <img
                          className="d-block w-100"
                          src={`${imageBaseURL}/${banner_image}`}
                          alt="Slide"
                        />
                       
                      </Carousel.Item>
                       

                      )
                    })}
          </Carousel>

       </div>
    </>
  )
}

export default Banner