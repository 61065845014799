import React, { useState } from 'react'
import InnerBanner from '../component/InnerBanner'
import Axios from '../Axios'
import { useEffect} from "react"
import { useSelector } from 'react-redux';
import {useParams} from "react-router-dom"
const ActivitiesDetails = () => {
   const {activityId} = useParams()
   const[activityDetailsData, setActivityDetailsData] = useState({})
   const[isError, setIsError] = useState("");
   const selectedLanguageReducer = useSelector(state => state.selectedLanguageReducer);
   const getApiData = async()=>{
      try{
         const res = await Axios.pgBaseUrl.get(`social-activities-details/single/${activityId}`)
           setActivityDetailsData(res.data.social_activities)
        
         console.log(res)
      }
      catch(error){
         setIsError(error.message)
      }

     }
   useEffect(() => {
      getApiData()
   }, [])
  const{id,title,description,image,title_english,description_english} = activityDetailsData;
  var imageBaseURL = Axios.imgBaseUrl+ "social"
  return (
    <>
         {isError !== "" && <p className='errorTxt'>{isError}</p>}
         <div className="inner-page">
            <InnerBanner/> 
            <div className="about-info" key={id}>
               <div className="container">
                  <div className="about-txt">
                     <div className="row">
                        <div className="col-12">
                        <div className='ac-details-wrap'>
                           <img src={`${imageBaseURL}/${image}`} alt="SocialImage" className="img-fluid activity-img"/>
                           
                           {/* <div className="custom_heading">
                              <h2>{title}</h2>
                           </div> */}
                           <div className="custom_heading">
                              {/* <h2>{title}</h2> */}
                              {selectedLanguageReducer.lang == 'eng' && <h2>{title_english}</h2>}
                              {selectedLanguageReducer.lang == 'ben' && <h2>{title}</h2>}
                           </div>
                              {selectedLanguageReducer.lang == 'eng' && <p>{description_english}</p>}
                              {selectedLanguageReducer.lang == 'ben' && <p>{description}</p>}
                               <div className='clearfix'></div>
                           </div>
                          
                        </div>
                       
                     </div>
                  </div>
               </div>
         </div>
        </div>
        
        
    </>
  )
}

export default ActivitiesDetails