import React from 'react'
import InnerBanner from '../component/InnerBanner'
import AdminTable from '../component/AdminTable'


const Administration = () => {
  return (
    <>
       <section>
         <div className="inner-page">
            <InnerBanner/>
            <AdminTable/>
         </div>
      </section>
    
    </>
  )
}

export default Administration