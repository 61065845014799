import React, { useState } from 'react'
import { useEffect } from "react"
import { useSelector } from 'react-redux';
import Axios from '../Axios'

const ParamparaIntro = () => {
  const[paramparaIntroData, setParamparaIntroData] = useState([])
  const[isError, setIsError] = useState("");
  const selectedLanguageReducer = useSelector(state => state.selectedLanguageReducer);
  const getApiData = async() =>{
     try{
      const res = await Axios.pgBaseUrl.get('/homepage-details')
      setParamparaIntroData(res.data.parampara_text)
     }
     catch(error){
        setIsError(error.message)
     }
  }
  useEffect(() => {
   getApiData() 
 
    
  }, [])
  return (
    <>
    {isError !== "" && <p className='errorTxt'>{isError}</p>}
        <div className='paramparaIntroSec'>
                <div className='container'>
                <div className='row'>
                    <div className='col-12 text-center'>
                    <blockquote className="blockquote">
                        {/* <p dangerouslySetInnerHTML={{__html:paramparaIntroData.parampara_text}} className='sanskritTxt'></p>  
                                   */}

                        {selectedLanguageReducer.lang == 'eng' && <p dangerouslySetInnerHTML={{__html:paramparaIntroData.parampara_text_english}} className='sanskritTxt'></p>  }
                        {selectedLanguageReducer.lang == 'ben' && <p dangerouslySetInnerHTML={{__html:paramparaIntroData.parampara_text}} className='sanskritTxt'></p>  }           
                    </blockquote>
                    </div>
                    </div>
                </div>
            </div>
     
   
      

    </>
  )
}

export default ParamparaIntro