import React from 'react'
import InnerBanner from '../component/InnerBanner'
import Religious from '../component/Religious.js'



function ReligiousProgrammes() {
 return (
    <>
    <section>
         <div className="inner-page">
           <InnerBanner/>
          <Religious/>
         </div>
      </section>
     
    </>
  )
}

export default ReligiousProgrammes