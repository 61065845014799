import React from 'react'
import { useNavigate } from 'react-router-dom'
import Axios from '../Axios'
import { useSelector } from 'react-redux';
import { useEffect , useState } from "react"
let dangerousText={}
const Footer = () => {
 const footerNavigate =  useNavigate();
 const[FooterData, setFooterData] = useState({})
 const[isError, setIsError] = useState("")
 const selectedLanguageReducer = useSelector(state => state.selectedLanguageReducer);
 const getApiData = async() =>{
   try{
    const res = await Axios.pgBaseUrl.get('/footer-content')
   //  console.log(res.data.data.address)
   // const printableText = res.data.data.address.replace(/(?:\r\n|\r|\n)/g, "<br />");
   // console.log(printableText)
   // res.data.data.address= res.data.data.address.replace(/<br\s*[\/]?>/gi, "\n");
   // console.log(res.data.data.address)
    setFooterData(res.data.data)
   }
   catch(error){
      setIsError(error.message)
   }
}
useEffect(() => {
 getApiData() 

  
}, [])

  return (
    <>
     <footer>
     {isError !== "" && <p className='errorTxt'>{isError}</p>}
         <div className="container">
            <div className="row">
               <div className="col-lg-6">
                  <div className="foot-box1">
                     <img src="/assets/images/footer-logo.png" alt="Logo" className="img-fluid"/>
                     {/* <p>{FooterData.footer_text}</p> */}
                     {selectedLanguageReducer.lang == 'eng' && <p dangerouslySetInnerHTML={{__html:FooterData.footer_text_english}}></p>}
                     {selectedLanguageReducer.lang == 'ben' && <p dangerouslySetInnerHTML={{__html:FooterData.footer_text}}></p>}
                     <div className="face-link">
                     <a href={FooterData.facebook_link}><i className="fa fa-facebook"></i></a>
                     </div>
                  </div>
               </div>
               <div className="col-lg-6">
                  <div className="row">
                     <div className="col-lg-6 col-md-6 col-sm-6">
                         {/* <div className="foot-box2">
                           <h2>Quick Links</h2>
                           <ul>
                              <li>
                                <button className='footerLink' onClick={()=>{footerNavigate('/')}}><i className="fa fa-angle-double-right"></i> Home</button>
                              </li>
                              <li>
                                 <button className='footerLink' onClick={()=>{footerNavigate('/about')}}><i className="fa fa-angle-double-right"></i> About</button>
                                 
                              </li>
                              <li>
                                 <button className='footerLink' onClick={()=>{footerNavigate('/administration')}}><i className="fa fa-angle-double-right"></i> Administration</button>
                              </li>
                              <li>
                                 <button className='footerLink' onClick={()=>{footerNavigate('/socialActivities')}}><i className="fa fa-angle-double-right"></i> Social Activities</button>
                              </li>
                              <li>
                                 <button className='footerLink' onClick={()=>{footerNavigate('/religiousProgrammes')}}><i className="fa fa-angle-double-right"></i> Religious Programmes</button>
                              </li>
                              <li>
                                 <button className='footerLink' onClick={()=>{footerNavigate('/photoGallery')}}> <i className="fa fa-angle-double-right"></i> Photo Gallery</button>
                              </li>
                              <li>
                                 <button className='footerLink' onClick={()=>{footerNavigate('/video')}}> <i className="fa fa-angle-double-right"></i> Video</button>
                              </li>
                              <li>
                                 <button className='footerLink' onClick={()=>{footerNavigate('/donate')}}><i className="fa fa-angle-double-right"></i> Donate</button>
                              </li>
                              <li>
                                 <button className='footerLink' onClick={()=>{footerNavigate('/publications')}}><i className="fa fa-angle-double-right"></i> Publications</button>
                                 
                              </li>
                              <li>
                              <button className='footerLink' onClick={()=>{footerNavigate('/contactUs')}}><i className="fa fa-angle-double-right"></i> ContactUs</button>
                              </li>
                           </ul>
                        </div>  */}
                     {selectedLanguageReducer.lang == 'eng' && <div className="foot-box2">
                           <h2>Quick Links</h2>
                           <ul>
                              <li>
                                <button className='footerLink' onClick={()=>{footerNavigate('/')}}><i className="fa fa-angle-double-right"></i> Home</button>
                              </li>
                              <li>
                                 <button className='footerLink' onClick={()=>{footerNavigate('/about')}}><i className="fa fa-angle-double-right"></i> About</button>
                                 
                              </li>
                              <li>
                                 <button className='footerLink' onClick={()=>{footerNavigate('/administration')}}><i className="fa fa-angle-double-right"></i> Administration</button>
                              </li>
                              <li>
                                 <button className='footerLink' onClick={()=>{footerNavigate('/socialActivities')}}><i className="fa fa-angle-double-right"></i> Social Activities</button>
                              </li>
                              <li>
                                 <button className='footerLink' onClick={()=>{footerNavigate('/religiousProgrammes')}}><i className="fa fa-angle-double-right"></i> Religious Programmes</button>
                              </li>
                              <li>
                                 <button className='footerLink' onClick={()=>{footerNavigate('/photoGallery')}}> <i className="fa fa-angle-double-right"></i> Photo Gallery</button>
                              </li>
                              <li>
                                 <button className='footerLink' onClick={()=>{footerNavigate('/video')}}> <i className="fa fa-angle-double-right"></i> Video</button>
                              </li>
                              <li>
                                 <button className='footerLink' onClick={()=>{footerNavigate('/donate')}}><i className="fa fa-angle-double-right"></i> Donate</button>
                              </li>
                              <li>
                                 <button className='footerLink' onClick={()=>{footerNavigate('/publications')}}><i className="fa fa-angle-double-right"></i> Publications</button>
                                 
                              </li>
                              <li>
                              <button className='footerLink' onClick={()=>{footerNavigate('/contactUs')}}><i className="fa fa-angle-double-right"></i> Contact Us</button>
                              </li>
                           </ul>
                        </div>}
                     {selectedLanguageReducer.lang == 'ben' && 
                         <div className="foot-box2">
                           <h2>দ্রুত লিঙ্ক</h2>
                           <ul>
                              <li>
                                <button className='footerLink' onClick={()=>{footerNavigate('/')}}><i className="fa fa-angle-double-right"></i> হোম</button>
                              </li>
                              <li>
                                 <button className='footerLink' onClick={()=>{footerNavigate('/about')}}><i className="fa fa-angle-double-right"></i> সম্পর্কিত </button>
                                 
                              </li>
                              <li>
                                 <button className='footerLink' onClick={()=>{footerNavigate('/administration')}}><i className="fa fa-angle-double-right"></i> প্রশাসন </button>
                              </li>
                              <li>
                                 <button className='footerLink' onClick={()=>{footerNavigate('/socialActivities')}}><i className="fa fa-angle-double-right"></i> সামাজিক কর্ম</button>
                              </li>
                              <li>
                                 <button className='footerLink' onClick={()=>{footerNavigate('/religiousProgrammes')}}><i className="fa fa-angle-double-right"></i> ধর্মীয় অনুষ্ঠান </button>
                              </li>
                              <li>
                                 <button className='footerLink' onClick={()=>{footerNavigate('/photoGallery')}}> <i className="fa fa-angle-double-right"></i> ফটো গ্যালারি</button>
                              </li>
                              <li>
                                 <button className='footerLink' onClick={()=>{footerNavigate('/video')}}> <i className="fa fa-angle-double-right"></i> ভিডিও</button>
                              </li>
                              <li>
                                 <button className='footerLink' onClick={()=>{footerNavigate('/donate')}}><i className="fa fa-angle-double-right"></i> দান করুন</button>
                              </li>
                              <li>
                                 <button className='footerLink' onClick={()=>{footerNavigate('/publications')}}><i className="fa fa-angle-double-right"></i> প্রকাশনা</button>
                                 
                              </li>
                              <li>
                              <button className='footerLink' onClick={()=>{footerNavigate('/contactUs')}}><i className="fa fa-angle-double-right"></i> যোগাযোগ করুন</button>
                              </li>
                           </ul>
                        </div>}

                     </div>
                     <div className="col-lg-6 col-md-6 col-sm-6">
                     {selectedLanguageReducer.lang == 'eng' && 
                        <div className="foot-box3">
                           <h2>Contact Us</h2>
                          
                           <ul>
                              <li><span><img src="/assets/images/location.png" alt="Contact"/></span> 
                               <span dangerouslySetInnerHTML={{__html:FooterData.address_english}}></span>
                              </li>
                              <li><span><img src="/assets/images/email-foot.png" alt="Contact"/></span> {FooterData.email}</li>
                              <li><span><img src="/assets/images/foot-phon.png" alt="Contact"/></span>
                               {FooterData.phone_one} <br/> {FooterData.phone_two} <br/> {FooterData.phone_three}
                              </li>
                           </ul>

                           </div>

                           }




                           {selectedLanguageReducer.lang == 'ben' &&
                           <div className="foot-box3">
                           <h2>যোগাযোগ করুন</h2>
                           <ul>
                              <li><span><img src="/assets/images/location.png" alt="Contact"/></span> 
                               <span dangerouslySetInnerHTML={{__html:FooterData.address}}></span>
                              </li>
                              <li><span><img src="/assets/images/email-foot.png" alt="Contact"/></span> {FooterData.email}</li>
                              <li><span><img src="/assets/images/foot-phon.png" alt="Contact"/></span>
                               {FooterData.phone_one_bengali} <br/> {FooterData.phone_two_bengali} <br/> {FooterData.phone_three_bengali}
                              </li>
                           </ul>
                           </div>
                          }

                       
                     </div>
                  </div>
               </div>
            </div>
         </div>
       </footer>
      <div className="foot_arw">
         <a href="#" id="return-to-top"><i className="fa fa-arrow-up" aria-hidden="true"></i></a>
      </div>
    </>
  )
}

export default Footer