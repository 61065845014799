import React from 'react'
import Activities from '../component/Activities'
import InnerBanner from '../component/InnerBanner'

function SocialActivities() {
  return (
    <>
     
      <section>
         <div className="inner-page">
           <InnerBanner/>
           <Activities/>
         </div>
      </section>
     
     
    </>
  )
}

export default SocialActivities