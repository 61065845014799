import React, { Component } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Axios from '../Axios';
import { Link } from "react-router-dom";
import { connect} from 'react-redux';
import Activities from './Activities';

// import { useSelector } from 'react-redux';
class HmSocial extends Component {
    constructor(props){
        super(props);
        // console.log("const ",props);
    }

   
 render() {

    
    let selectedLanguage = this.props.selectedLanguage;
    console.log("PROPS ",this.props)
    const hmSocialData = this.props.parentToChild
    var imageBaseURL = Axios.imgBaseUrl+ "social"
    // console.log(bannerData)
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1
    }
    // const selectedLanguageReducer = useSelector(state => state.selectedLanguageReducer);
    return (
        
        <div className='social-sec'>
            <div className='container'>
            <div className="custom-heading text-center">
                  {/* <h1>Social Activities</h1> */}
                  <>
                                    {selectedLanguage.lang == 'ben' && <h1>সামাজিক কর্ম</h1>}
                                    {selectedLanguage.lang == 'eng' && <h1>Social Activities</h1>}
                                    </>
                  <em className="bg-drk"></em>
               </div>
               <div className="socialinr">
               <Slider {...settings}>
                        {hmSocialData.map((post)=>{
                            const {id,title,image,title_english} = post;
                            return(
                                <Link to={`/socialActivities/${id}`} className="transBorder">
                                <div className="item" key={id}>
                                <div className="social-item">
                                <span><img src={`${imageBaseURL}/${image}`} alt="Social Activities"/></span>                           
                                 {selectedLanguage.lang == 'eng' && <h3>{title_english}</h3>}
                                 {selectedLanguage.lang == 'ben' && <h3>{title}</h3>}
                                </div>
                                </div> 
                                </Link>    
                            )
                        })
                    }
                        {/* <div className="item">
                            <div className="social-item">
                            <span><img src="assets/images/Social2.png" alt="Social Activities"/></span>                           
                            <h3>Image Title Here</h3>
                            </div>
                        </div>
                        <div className="item">
                            <div className="social-item">
                            <span><img src="assets/images/Social3.png" alt="Social Activities"/></span>                           
                            <h3>Image Title Here</h3>
                            </div>
                        </div>
                        <div className="item">
                            <div className="social-item">
                            <span><img src="assets/images/Social3.png" alt="Social Activities"/></span>                           
                            <h3>Image Title Here</h3>
                            </div>
                        </div>
                        <div className="item">
                            <div className="social-item">
                            <span><img src="assets/images/Social3.png" alt="Social Activities"/></span>                           
                            <h3>Image Title Here</h3>
                            </div>
                        </div> */}
                       
                    </Slider>

               </div>
                    
        
            </div>
         </div>
      );
    }
  }

  const mapStateToProps = (state) => ({
    // console.log("State",state);
    selectedLanguage : state.selectedLanguageReducer
    // const { todos } = state
    // return { state }
  })
  
  export default connect(mapStateToProps)(HmSocial);