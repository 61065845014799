import React from 'react'
import Axios from '../Axios'
import { useSelector } from 'react-redux';

const AboutCard = (props) => {
 var imageBaseURL = Axios.imgBaseUrl+ "about_image"
 const selectedLanguageReducer = useSelector(state => state.selectedLanguageReducer);
  return (
    <div>

         <div className="about-info">
               <div className="container">
                  <div className="about-txt">
                  <div className="row">
                        <div className="col-lg-6">
                           <div className="about-img">
                              {props.about && props.aboutData?.description && <img src={`${imageBaseURL}/${props.aboutData?.image}`} alt={`${props.aboutData?.image}`} className="img-fluid"/>}
                              {props.donate && props.aboutData?.donate_description && <img src={`${imageBaseURL}/${props.aboutData?.donate_image}`} alt={`${props.aboutData?.donate_image}`} className="img-fluid"/>}

                           </div>
                        </div>
                        <div className="col-lg-6">
                           <div className="custom_heading">
                              {/* {props.about && props.aboutData?.description && <h2>About Us</h2>} */}
                              {selectedLanguageReducer.lang == 'eng' && 
                              <>
                              {props.about && props.aboutData?.description && <h2>About Us</h2>}
                              </>
                               }
                               {selectedLanguageReducer.lang == 'ben' && 
                              <>
                              {props.about && props.aboutData?.description && <h2>আমাদের সম্পর্কে জানুন</h2>}
                              </>
                               }
                              {/* {props.donate && props.aboutData?.donate_description && <h2>Donate</h2>} */}
                              {selectedLanguageReducer.lang == 'eng' && 
                              <>
                              {props.donate && props.aboutData?.donate_description && <h2>Donate</h2>}
                              </>
                               }
                               {selectedLanguageReducer.lang == 'ben' && 
                              <>
                              {props.donate && props.aboutData?.donate_description &&  <h2>দান করুন</h2>}
                              </>
                               }
                           </div>
                            {/* {props.about && props.aboutData?.description && <p dangerouslySetInnerHTML={{__html:props.aboutData.description}}></p>} */}
                            
                            {selectedLanguageReducer.lang == 'eng' && 
                              <>
                               {props.about && props.aboutData?.description && <p dangerouslySetInnerHTML={{__html:props.aboutData.description_english}}></p>}
                              </>
                               }
                               {selectedLanguageReducer.lang == 'ben' && 
                              <>
                              {props.about && props.aboutData?.description && <p dangerouslySetInnerHTML={{__html:props.aboutData.description}}></p>}
                              </>
                               }
                            
                            {selectedLanguageReducer.lang == 'eng' && 
                              <>
                               {props.donate && props.aboutData?.donate_description && <p dangerouslySetInnerHTML={{__html:props.aboutData.donate_description_english}}></p>}
                              </>
                               }
                               {selectedLanguageReducer.lang == 'ben' && 
                              <>
                              {props.donate && props.aboutData?.donate_description && <p dangerouslySetInnerHTML={{__html:props.aboutData.donate_description}}></p>}
                              </>
                               }
                            
                            
                            
                            {/* {props.donate && props.aboutData?.donate_description && <p dangerouslySetInnerHTML={{__html:props.aboutData.donate_description}}></p>}           */}
                        </div>
                     </div>
                     

                  </div>
               </div>
         </div>
    </div>
  )
}

export default AboutCard