import React from 'react'
import InnerBanner from '../component/InnerBanner'
import Axios from '../Axios'
import { useEffect , useState } from "react"
import { useSelector } from 'react-redux';
import {useParams} from "react-router-dom"

const PublicationDetails = () => {
   const {publicationId} = useParams() 
   const[publicationData, setpublicationData] = useState({})
   const[isError, setIsError] = useState("");
   const selectedLanguageReducer = useSelector(state => state.selectedLanguageReducer);
   var imageBaseURL = Axios.imgBaseUrl+ "publication"
   
   const getApiData = async()=>{
      try{
         const res = await Axios.pgBaseUrl.get(`publication/details/${publicationId}`)
         setpublicationData(res.data.publications)
        
         console.log(res)
      }
      catch(error){
         setIsError(error.message)
      }

     }
   useEffect(() => {
      getApiData()
   }, []) 
   const{id,title,description,image,available,title_english,description_english} = publicationData;
  return (
    
   <>
      {isError !== "" && <p className='errorTxt'>{isError}</p>}
        <div className="inner-page">
            <InnerBanner/> 
            <div className="about-info" key={id}>
               <div className="container">
                  <div className="about-txt">
                     <div className="row">
                        <div className="col-12">
                        <div className='ac-details-wrap'>
                        <div className="publication-img position-relative">
                              {selectedLanguageReducer.lang == 'eng' && 
                              <>
                              {available === "Y" ? <span className='avail'>Available</span> 
                              : <span className='notAvail'>Not Available</span>}
                              </> 
                              }
                              {selectedLanguageReducer.lang == 'ben' && 
                              <>
                              {available === "Y" ? <span className='avail'>উপলব্ধ</span> 
                              : <span className='notAvail'>অনুপলব্ধ</span>}
                              </>
                              }

                                   
                                    {image === null ? <img src='/assets/images/placeholder-image.webp' alt="PublicationImage" className="img-fluid activity-img"/> : <img src={`${imageBaseURL}/${image}`} alt="PublicationImage" className="img-fluid activity-img"/>}
                            
                            
                            
                            </div>
                           
                            <div className="custom_heading">
                             
                              {selectedLanguageReducer.lang == 'eng' && <h2>{title_english}</h2>}
                              {selectedLanguageReducer.lang == 'ben' && <h2>{title}</h2>}
                           </div>
                           {selectedLanguageReducer.lang == 'eng' && <p>{description_english}</p>}
                           {selectedLanguageReducer.lang == 'ben' && <p>{description}</p>}
                           <div className='clearfix'></div>
                           </div>
                          
                        </div>
                       
                     </div>
                  </div>
               </div>
         </div>
        </div>
   </>
  )
}

export default PublicationDetails